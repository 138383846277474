<template>
  <div class="box">
    <div class="box-header">
      <h4>{{ pageTitle }}</h4>
    </div>
    <free-form
      v-if="model"
      :submit-text="pageTitle"
      :model="model"
      :is-edit="false"
      @on-submit="createFreeCreate"
    >
    </free-form>
    <placeholder-form v-else></placeholder-form>
  </div>
</template>

<script>
import MoneyService from '@admin/services/MoneyService'
import flatry from '@admin/utils/flatry'
import PlaceholderForm from '@core/components/Placeholder/PlaceholderForm'
import FreeForm from './components/_From'

export default {
  name: 'FreeCreate',
  components: { PlaceholderForm, FreeForm },
  data () {
    return {
      pageTitle: '创建储值选项数据',
      model: {
        price: '',
        price_des: '储值',
        add_price: '',
        add_price_des: '额外加送',
        is_tui: false,
        sort: 0
      }
    }
  },
  methods: {
    async createFreeCreate (model, success, callback) {
      const { data } = await flatry(MoneyService.create(model))

      if (data) {
        this.$message.success(data.msg)
        this.$router.replace({
          path: '/money/update',
          query: { id: data.data }
        })

        success()
      }

      callback()
    }
  }
}
</script>
<style lang='scss' scoped>
</style>
